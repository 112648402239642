/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { BlocksDocument, type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { inject as service } from '@ember/service';
import type FinCustomConfiguration from 'embercom/objects/visual-builder/configuration/step/fin-custom';
import type FinCustom from 'embercom/models/operator/visual-builder/step/fin-custom';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { type Block } from 'embercom/models/common/blocks/block';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import { type Type as AttributeDescriptorType } from 'embercom/models/operator/visual-builder/attribute-descriptor';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';
import { triggerCharacter } from '@intercom/embercom-prosemirror-composer/lib/composer/typeahead/trigger-character';
import AiPromptDataPopover from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom/ai-prompt-data-popover';
import generateUUID from 'embercom/lib/uuid-generator';
import { type OutputParam } from 'embercom/components/workflows/graph-editor/node-items/steps/fin-custom';
import ComposerConfig from 'embercom/objects/workflows/graph-editor/chat-message/composer-config';
import { next } from '@ember/runloop';
import { captureException } from 'embercom/lib/sentry';

type Operation = 'use_action' | 'read_attribute' | 'update_attribute';

export const GOAL_MAX_CHARACTER_COUNT = 150;

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

interface Arguments {
  step: FinCustom;
  stepConfig: FinCustomConfiguration;
  isReadOnly: boolean;
  editorState: EditorState;
  onAddOutputParam: (param: OutputParam) => AttributeDescriptor;
}

interface Instruction {
  blocksDocument: BlocksDocument;
  id: string;
  isNew: boolean;
  instructionComposerApi?: ComposerPublicAPI;
}

export const aiPromptDataTypeAheadConfig = (
  allowedOperations: Operation[] = ['read_attribute', 'update_attribute', 'use_action'],
) => {
  return {
    name: 'ai-prompt-data',
    matcher: triggerCharacter('@', { allowSpaces: true }),
    component: 'workflows/graph-editor/node-items/steps/fin-custom/ai-prompt-data-typeahead',
    attrs: {
      allowedOperations,
    },
  };
};

interface FocusState {
  type: 'goal' | 'guideline' | 'instruction' | null;
  index: number | null; // Only used for instruction type
}

export default class Editor extends Component<Signature> {
  @service store: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked focusState: FocusState = { type: null, index: null };

  @tracked goalBlocksDoc: BlocksDocument;
  @tracked guidelineBlocksDoc: BlocksDocument;
  @tracked goalComposerApi: ComposerPublicAPI | null = null;
  @tracked guidelineComposerApi: ComposerPublicAPI | null = null;
  @tracked instructions: Instruction[] = [];
  @tracked showAiTaskGenModal = false;
  @tracked aiTaskGenBlocksDoc: BlocksDocument = new BlocksDocument([]);
  @tracked aiTaskGenComposerApi: ComposerPublicAPI | null = null;
  @tracked taskGenerationInProgress = false;
  @tracked showClearDraft = false;
  @tracked openGuidanceSection: string | null = null;

  constructor(owner: unknown, args: Arguments) {
    super(owner, args);

    // @ts-ignore - SyncHasMany is compatible with BlocksDocument but types don't match
    this.goalBlocksDoc = new BlocksDocument(this.args.step.blocks);
    // @ts-ignore - SyncHasMany is compatible with BlocksDocument but types don't match
    this.guidelineBlocksDoc = new BlocksDocument(this.args.step.guideline);
    this.instructions = this.args.step.instructions?.map((blocks) => ({
      ...this.buildInstruction(blocks),
      isNew: false,
    }));
  }

  get app() {
    return this.appService.app;
  }

  buildInstruction(blocks: Block[]) {
    return {
      id: generateUUID(),
      blocksDocument: new BlocksDocument(blocks),
    };
  }

  @action
  onFocus(type: 'goal' | 'guideline' | 'instruction', index: number | null = null) {
    this.focusState = { type, index };
  }

  @action
  onBlur() {
    this.focusState = { type: null, index: null };
  }

  @action
  onInstructionComposerReady(index: number, composerApi: ComposerPublicAPI) {
    this.instructions[index].instructionComposerApi = composerApi;

    if (this.instructions[index].isNew) {
      composerApi.composer.commands.focus();
    }
  }

  @action
  onGuidelineComposerReady(composerApi: ComposerPublicAPI) {
    this.guidelineComposerApi = composerApi;
  }

  @action
  onGoalComposerReady(composerApi: ComposerPublicAPI) {
    this.goalComposerApi = composerApi;
  }

  @action
  onAiTaskGenComposerReady(composerApi: ComposerPublicAPI) {
    this.aiTaskGenComposerApi = composerApi;
    next(this, () => {
      this.aiTaskGenComposerApi?.composer.commands.focus();
    });
  }

  composerConfig(
    placeholder: string,
    showAiPromptDataInserter = true,
    maxCharacterCount?: number,
    allowedAiPromptDataOperations?: Operation[],
  ) {
    let config = new ComposerConfig({
      app: this.app,
      resolver: this.args.stepConfig.attributeInfoResolver,
      placeholder,
      allowMentions: false,
      hideTextFormatter: true,
    });

    config.autoFocus = false;
    config.isInteractivityDisabled = this.args.isReadOnly;
    if (this.args.step.isV3) {
      config.tools = config.tools.filter((tool) => tool.name !== 'template-inserter');
    }

    if (this.args.step.isV3 && showAiPromptDataInserter) {
      config.aiPromptData = {
        onClickComponent: AiPromptDataPopover as unknown as Component,
        onAddOutputParam: this.args.onAddOutputParam,
        resolver: this.args.stepConfig.attributeInfoResolver,
      };

      config.insertTypeahead(aiPromptDataTypeAheadConfig(allowedAiPromptDataOperations));
    }

    if (this.args.step.isV3 && maxCharacterCount) {
      config.maxCharacterCount = maxCharacterCount;
    }

    return config;
  }

  get templateOptionsList() {
    return [
      {
        inserter: this.insertTemplate,
        resolver: this.args.stepConfig.attributeInfoResolver,
        component: 'workflows/graph-editor/node-items/steps/fin-custom/action-inserter',
        componentShouldReplaceItem: true,
        isDisabled: this.args.stepConfig.attributeInfoResolver.customActions.length < 1,
      },
      {
        inserter: this.insertTemplate,
        resolver: this.args.stepConfig.attributeInfoResolver,
        component: 'workflows/graph-editor/node-items/steps/fin-custom/attribute-inserter',
        componentShouldReplaceItem: true,
      },
    ];
  }

  get goalComposerConfig() {
    return this.composerConfig(
      this.intl.t('operator.workflows.visual-builder.fin-custom-settings.prompt-placeholder', {
        isFinTaskV3: this.args.step.isV3,
        htmlSafe: true,
      }),
      false,
      GOAL_MAX_CHARACTER_COUNT,
    );
  }

  get guidelineComposerConfig() {
    return this.composerConfig(
      this.intl.t(
        'operator.workflows.visual-builder.fin-custom-settings.guideline-prompt-placeholder',
        {
          isFinTaskV3: this.args.step.isV3,
          htmlSafe: true,
        },
      ),
      false,
    );
  }

  get firstInstructionComposerConfig() {
    return this.composerConfig(
      this.intl.t(
        'operator.workflows.visual-builder.fin-custom-settings.instruction-prompt-placeholder',
        { htmlSafe: true },
      ),
    );
  }

  get canUseAiTaskGeneration() {
    if (!this.app.canUseFinTaskAITaskGeneration || !this.args.step.isV3) {
      return false;
    }

    let goalText = this.args.step.blocks.map((block) => block.text).join('');
    let instructionsText = this.args.step.instructions
      .map((instruction) => instruction.map((block: $TSFixMe) => block.text).join(''))
      .join('');
    return (goalText.trim() + instructionsText.trim()).length <= 10;
  }

  get aiTaskGenComposerConfig() {
    return this.composerConfig(
      this.intl.t(
        'operator.workflows.visual-builder.fin-custom-settings.ai-task-gen-modal.placeholder',
        { htmlSafe: true },
      ),
      true,
      undefined,
      ['use_action'],
    );
  }

  get isAiTaskGenerationPromptEmpty() {
    return !this.aiTaskGenBlocksDoc.blocks.some((block: Block) => block.text.trim() !== '');
  }

  @action
  onInstructionSelection(idx: number) {
    this.onFocus('instruction', idx);
    this.instructions[idx]?.instructionComposerApi?.composer.commands.focus();
  }

  @action
  onGoalSelection() {
    this.onFocus('goal');
    this.goalComposerApi?.composer.commands.focus();
  }

  @action
  onGuidelineSelection() {
    this.onFocus('guideline');
    this.guidelineComposerApi?.composer.commands.focus();
  }

  get instructionComposerConfig() {
    return this.composerConfig(
      this.intl.t(
        'operator.workflows.visual-builder.fin-custom-settings.list-instruction-prompt-placeholder',
        { htmlSafe: true },
      ),
    );
  }

  get goalCharactersRemaining() {
    let doc = this.goalComposerApi?.composer.state.prosemirrorState.doc;
    return GOAL_MAX_CHARACTER_COUNT - (doc?.textBetween(0, doc?.content.size, '\n').length || 0);
  }

  get shouldShowCharactersRemaining() {
    return (
      this.isFocusedOnGoalComposer && this.goalCharactersRemaining <= GOAL_MAX_CHARACTER_COUNT * 0.1
    );
  }

  @action
  updateGoal(blocksDoc: BlocksDocument) {
    this.maybeHideClearDraft();
    let store = this.store;
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(store, block),
    );

    this.unloadBlocks(this.args.step.blocks);
    this.args.step.set('blocks', blockFragments);
    this.args.step.notifyPropertyChange('blocks');
  }

  @action
  updateGuideline(blocksDoc: BlocksDocument) {
    this.maybeHideClearDraft();
    let store = this.store;
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(store, block),
    );

    this.unloadBlocks(this.args.step.guideline);
    this.args.step.set('guideline', blockFragments);
    this.args.step.notifyPropertyChange('guideline');
  }

  @action
  addNewInstruction(event: Event) {
    this.addInstruction(-1, event); // add empty instruction at first index
  }

  @action
  addInstruction(index: number, event: Event) {
    this.maybeHideClearDraft();
    event.stopPropagation();

    let newInstructionModel = [
      this.store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: '', // let the component decide what the default or placeholder text should be
      }),
    ];

    let newInstruction = { ...this.buildInstruction(newInstructionModel), isNew: true };

    this.args.step.instructions.splice(index + 1, 0, newInstructionModel); // Insert empty instruction after current index

    this.instructions.splice(index + 1, 0, newInstruction);
    this.args.step.instructions = [...this.args.step.instructions];

    this.instructions = this.instructions.map((instruction, idx) => {
      return {
        ...instruction,
        blocksDocument: new BlocksDocument(this.args.step.instructions[idx]),
      };
    });

    this.args.step.notifyPropertyChange('instructions');
  }

  @action
  updateInstruction(index: number, blocksDoc: BlocksDocument) {
    this.maybeHideClearDraft();
    let store = this.store;
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(store, block),
    );

    this.unloadBlocks(this.args.step.instructions[index]);
    this.args.step.instructions[index] = blockFragments;
    this.args.step.notifyPropertyChange('instructions');
  }

  @action
  deleteInstruction(index: number) {
    this.maybeHideClearDraft();
    if (this.args.step.instructions.length > 1) {
      this.unloadBlocks(this.args.step.instructions[index]);
      this.args.step.instructions = this.args.step.instructions.filter((_, i) => i !== index);
      this.instructions = this.instructions
        .filter((_, i) => i !== index)
        .map((instruction, idx) => {
          return {
            ...instruction,
            blocksDocument: new BlocksDocument(this.args.step.instructions[idx]),
          };
        });
    }
  }

  private unloadBlocks(blocks: any) {
    blocks?.forEach((fragment: Block) => {
      if (fragment && typeof fragment.unloadRecord === 'function') {
        fragment.unloadRecord();
      }
    });
  }

  get isFocusedOnGoalComposer() {
    return this.focusState.type === 'goal';
  }

  get isFocusedOnGuidelineComposer() {
    return this.focusState.type === 'guideline';
  }

  get isInstructionFocused() {
    return this.focusState.type === 'instruction';
  }

  get focusedInstructionIndex() {
    return this.focusState.type === 'instruction' ? this.focusState.index : null;
  }

  @action
  insertTemplate(attribute: string): null {
    let activeComposer;

    if (this.showAiTaskGenModal) {
      activeComposer = this.aiTaskGenComposerApi?.composer;
    } else {
      activeComposer = this.goalComposerApi?.composer;
    }

    let prosemirrorState = activeComposer?.state.prosemirrorState;
    if (prosemirrorState) {
      let templateSpec = prosemirrorState.schema.nodes.template;
      let pos = prosemirrorState.selection.from;
      let tr = prosemirrorState.tr;
      let node = templateSpec.createAndFill({ attributePath: attribute, fallback: '' });
      tr.insert(pos, node);
      activeComposer?.commands.dispatch(tr);
      activeComposer?.commands.focus();
    }
    return null;
  }

  @action
  openAiTaskGenModal() {
    this.captureAnalyticsForAiInstructionsGeneration('cta_clicked');
    this.showAiTaskGenModal = true;
  }

  @action
  hideAiTaskGenModal() {
    this.showAiTaskGenModal = false;
  }

  @action
  updateAiTaskGenBlocks(blocksDoc: BlocksDocument) {
    this.aiTaskGenBlocksDoc = blocksDoc;
  }

  @action
  clearDraft() {
    this.captureAnalyticsForAiInstructionsGeneration('draft_cleared');
    this.showClearDraft = false;
    this.args.step.name = '';
    this.createAIGeneratedGoal('');
    this.createAIGeneratedInstructions(['', '']);
    this.args.step.finOutputParams.forEach((param) => {
      this.args.editorState.deleteLocalVariable(param);
    });
    this.args.step.finOutputParams.clear();
    this.aiTaskGenBlocksDoc = new BlocksDocument([]);
  }

  @action
  async generateTaskInstructions() {
    this.captureAnalyticsForAiInstructionsGeneration('generation_started');
    this.taskGenerationInProgress = true;
    try {
      let response = await post(
        `/ember/operator_workflows/generate_fin_task_instructions?app_id=${this.app.id}`,
        {
          description: this.aiTaskGenBlocksDoc.blocks,
          workflow_instance_id: this.args.editorState.workflow.workflowInstanceId,
          external_step_id: this.args.step.externalStepId,
        },
      );
      this.args.step.name = response.title.trim();
      this.createAIGeneratedGoal(response.goal.trim());
      this.createAIGeneratedInstructions(response.instructions, response.output_variables);

      this.hideAiTaskGenModal();
      this.showClearDraft = true;

      this.captureAnalyticsForAiInstructionsGeneration('generated');
    } catch (e) {
      this.notificationsService.notifyError(
        e.jqXHR?.responseJSON?.errors?.firstObject?.message ||
          this.intl.t(
            'operator.workflows.visual-builder.fin-custom-settings.ai-task-gen-modal.toast-error',
          ),
      );
      this.captureAnalyticsForAiInstructionsGeneration('generation_failed');
    }
    this.taskGenerationInProgress = false;
  }

  maybeHideClearDraft() {
    if (!this.showClearDraft) {
      return;
    }

    if (!this.taskGenerationInProgress) {
      this.showClearDraft = false;
      this.captureAnalyticsForAiInstructionsGeneration('draft_accepted');
      this.aiTaskGenBlocksDoc = new BlocksDocument([]);
    }
  }

  createAIGeneratedGoal(goal: string) {
    let blocks = [
      this.store.createFragment('common/blocks/paragraph', {
        type: 'paragraph',
        text: goal,
      }),
    ];
    let goalBlocksDoc = new BlocksDocument(blocks);
    this.updateGoal(goalBlocksDoc);

    // @ts-ignore - SyncHasMany is compatible with BlocksDocument but types don't match
    this.goalBlocksDoc = goalBlocksDoc;
  }

  createAIGeneratedInstructions(
    instructions: string[],
    outputVariables: {
      name: string;
      description: string;
      id: string;
      type: AttributeDescriptorType;
    }[] = [],
  ) {
    let outputVariableMap = new Map<string, AttributeDescriptor>();

    outputVariables.forEach((variable) => {
      let attributeDescriptor = this.args.editorState.createLocalVariable(
        variable.name,
        variable.type,
        false,
        undefined,
        variable.id,
      );
      attributeDescriptor.description = variable.description;

      this.args.step.finOutputParams.addObject(attributeDescriptor);
      outputVariableMap.set(variable.name, attributeDescriptor);
    });

    this.args.step.instructions = instructions.map((instruction) => {
      return instruction.split('\n').map((text) => {
        return this.store.createFragment('common/blocks/paragraph', {
          type: 'paragraph',
          text,
          class: 'no-margin',
        });
      });
    });

    this.instructions = this.args.step.instructions?.map((blocks) => ({
      ...this.buildInstruction(blocks),
      isNew: false,
    }));
  }

  @action
  onGuidanceToggle(sectionId: string | null) {
    this.openGuidanceSection = this.openGuidanceSection === sectionId ? null : sectionId;
  }

  private captureAnalyticsForAiInstructionsGeneration(action: string) {
    try {
      let rawInstructions = this.args.step.instructions.map((instruction) =>
        instruction.map((block: $TSFixMe) => block?.text).join('\n'),
      );

      let rawPrompt =
        this.aiTaskGenBlocksDoc?.blocks.map((block: $TSFixMe) => block?.text).join('\n') || '';

      return this.intercomEventService.trackAnalyticsEvent({
        action,
        object: 'ai_instructions_generation',
        place: 'instructions_step',
        section: 'workflows_editor',
        external_step_id: this.args.step.externalStepId,
        instructions: rawInstructions,
        prompt: rawPrompt,
      });
    } catch (e) {
      captureException(e, {
        extra: {
          external_step_id: this.args.step.externalStepId,
        },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Workflows::GraphEditor::NodeItems::Steps::FinCustom::Editor': typeof Editor;
    'workflows/graph-editor/node-items/steps/fin-custom/editor': typeof Editor;
  }
}
